export default {
    data() {
        return {
            dataList: [],
            dataListen: [
                {
                    type: 'SST',
                    img: require("../../../assets/practice/ch/listening/SST.jpg"),
                    imgEn: require("../../../assets/practice/en/listening/SST.png"),
                    h1: "Summarize Spoken Text",
                    h2: "Listening and writing",
                    tag: ['Grammar', 'Vocabulary', 'Spelling'],
                    clid: 28,
                },
                {
                    type: 'MCM',
                    img: require("../../../assets/practice/ch/listening/MCM.jpg"),
                    imgEn: require("../../../assets/practice/en/listening/MCM.png"),
                    h1: "Choose multiple answers",
                    h2: "Listening",
                    clid: 29,
                },
                {
                    type: 'FIB',
                    img: require("../../../assets/practice/ch/listening/FIB.jpg"),
                    imgEn: require("../../../assets/practice/en/listening/FIB.png"),
                    h1: "Fill in the blanks",
                    h2: "Listening and writing",
                    clid: 30,
                },
                {
                    type: 'HCS',
                    img: require("../../../assets/practice/ch/listening/HCS.jpg"),
                    imgEn: require("../../../assets/practice/en/listening/HCS.png"),
                    h1: "Highlight Correct Summary",
                    h2: "Listening and reading",
                    clid: 31,
                },
                {
                    type: 'MCS',
                    img: require("../../../assets/practice/ch/listening/MCS.jpg"),
                    imgEn: require("../../../assets/practice/en/listening/MCS.png"),
                    h1: "Choose Single Answer",
                    h2: "Listening",
                    clid: 32,
                },
                {
                    type: 'SMW',
                    img: require("../../../assets/practice/ch/listening/SMW.jpg"),
                    imgEn: require("../../../assets/practice/en/listening/SMW.png"),
                    h1: "Select Missing Words",
                    h2: "Listening",
                    clid: 33
                },
                {
                    type: 'HIW',
                    img: require("../../../assets/practice/ch/listening/HIW.jpg"),
                    imgEn: require("../../../assets/practice/en/listening/HIW.png"),
                    h1: "Highlight Incorrect Words",
                    h2: "Listening and reading",
                    clid: 34
                },
                {
                    type: 'WFD',
                    img: require("../../../assets/practice/ch/listening/WFD.jpg"),
                    imgEn: require("../../../assets/practice/en/listening/WFD.png"),
                    h1: "Write From Dictation",
                    h2: "Listening and writing",
                    clid: 35
                }
            ],
            dataSpeak: [
                {
                    type: 'RA',
                    img: require("../../../assets/practice/ch/speaking/RA.jpg"),
                    imgEn: require("../../../assets/practice/en/speaking/RA.png"),
                    h1: "Read Aloud",
                    h2: "Reading and speaking",
                    tag: ['Pronunciation', 'Oral fluency'],
                    clid: 36
                },
                {
                    type: 'RS',
                    img: require("../../../assets/practice/ch/speaking/RS.jpg"),
                    imgEn: require("../../../assets/practice/en/speaking/RS.png"),
                    h1: "Repeat Sentence",
                    h2: "Listening and speaking",
                    tag: ['Pronunciation', 'Oral fluency'],
                    clid: 37
                },
                {
                    type: 'DI',
                    img: require("../../../assets/practice/ch/speaking/DI.jpg"),
                    imgEn: require("../../../assets/practice/en/speaking/DI.png"),
                    h1: "Describe Image",
                    h2: "Speaking",
                    tag: ['Pronunciation', 'Oral fluency'],
                    clid: 38
                },
                {
                    type: 'RL',
                    img: require("../../../assets/practice/ch/speaking/RL.jpg"),
                    imgEn: require("../../../assets/practice/en/speaking/RL.png"),
                    h1: "Re-tell Lecture",
                    h2: "Listening and speaking",
                    tag: ['Pronunciation', 'Oral fluency'],
                    clid: 39
                },
                {
                    type: 'ASQ',
                    img: require("../../../assets/practice/ch/speaking/ASQ.jpg"),
                    imgEn: require("../../../assets/practice/en/speaking/ASQ.png"),
                    h1: "Answer Short Question",
                    h2: "Listening and speaking",
                    clid: 40
                },
            ],
            dataRead: [
                {
                    type: 'MCM',
                    img: require("../../../assets/practice/ch/reading/MCM.jpg"),
                    imgEn: require("../../../assets/practice/en/reading/MCM.png"),
                    h1: "Choose multiple answers ",
                    h2: "Reading",
                    clid: 42
                },
                {
                    type: 'MCS',
                    img: require("../../../assets/practice/ch/reading/MCS.jpg"),
                    imgEn: require("../../../assets/practice/en/reading/MCS.png"),
                    h1: "Choose single answer ",
                    h2: "Reading",
                    clid: 43
                },
                {
                    type: 'RO',
                    img: require("../../../assets/practice/ch/reading/RO.jpg"),
                    imgEn: require("../../../assets/practice/en/reading/RO.png"),
                    h1: "Re-order Paragraphs",
                    h2: "Reading",
                    clid: 44
                },
                {
                    type: 'RFIB',
                    img: require("../../../assets/practice/ch/reading/RFIB.jpg"),
                    imgEn: require("../../../assets/practice/en/reading/RFIB.png"),
                    h1: "Reading：Fill in the Blanks",
                    h2: "Reading",
                    clid: 52
                },
                {
                    type: 'FIBW',
                    img: require("../../../assets/practice/ch/reading/FIBW.jpg"),
                    imgEn: require("../../../assets/practice/en/reading/FIBW.png"),
                    h1: "R&W: Fill in the blanks",
                    h2: "Reading and writing",
                    clid: 53
                },
            ],
            dataWrite: [
                {
                    type: 'SWT',
                    img: require("../../../assets/practice/ch/writing/SWT.jpg"),
                    imgEn: require("../../../assets/practice/en/writing/SWT.png"),
                    h1: "Summarize write Text",
                    h2: "Reading and writing",
                    tag: ['Grammar', 'Vocabulary'],
                    clid: 45
                },
                {
                    type: 'WE',
                    img: require("../../../assets/practice/ch/writing/WE.jpg"),
                    imgEn: require("../../../assets/practice/en/writing/WE.png"),
                    h1: "Write Essay",
                    h2: "Writing",
                    tag: ['Grammar', 'spelling', 'discourse'],
                    clid: 46
                },
            ],
        }
    },
    mounted() {
        this.init(this.$route.name);
    },
    watch: {
        $route(to, from) {
            this.init(to.name);
        }
    },
    methods: {
        init(e) {
            switch (e) {
                case 'GreListen':
                case 'GrePracticeListen':
                    this.dataList = this.dataListen;
                    sessionStorage.setItem('classify', 'Listen');
                    break;
                case 'GreSpeak':
                case 'GrePracticeSpeak':
                    this.dataList = this.dataSpeak;
                    sessionStorage.setItem('classify', 'Speak');
                    break;
                case 'GreRead':
                case 'GrePracticeRead':
                    this.dataList = this.dataRead;
                    sessionStorage.setItem('classify', 'Read');
                    break;
                case 'GreWrite':
                case 'GrePracticeWrite':
                    this.dataList = this.dataWrite;
                    sessionStorage.setItem('classify', 'Write');
                    break;

                default:
                    break;
            }
        },
        handleToDetail(item) {
            sessionStorage.setItem('clid', item.clid);
            sessionStorage.setItem('type', item.type);
            if (this.$route.name.indexOf('Practice') >= 0) {
                this.$router.push({
                    name: 'GrePracticeDetail',
                    query: {
                        clid: item.clid,
                        type: item.type
                    }
                })
            } else {
                this.$router.push({
                    name: 'GreDetail',
                    query: {
                        clid: item.clid,
                        type: item.type
                    }
                })
            }
        }
    },
}